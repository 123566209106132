import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class ListingsSearchIndexService {
  
  constructor(
      protected http: HttpClient,
	  @Inject('API_URL') private apiContext: string) {
  }
  
  async create(): Promise<void> {
    
    return await firstValueFrom(this.http.post<void>(`${this.apiContext}/listings-search-indexes`, {}));
  }
  
  async startReindex(newIndexName: string): Promise<void> {
    
    return await firstValueFrom(this.http.post<void>(`${this.apiContext}/listings-search-indexes/${newIndexName}`, {}));
  }
  
  async finishReindex(oldIndexName: string): Promise<void> {
    
    return await firstValueFrom(this.http.delete<void>(`${this.apiContext}/listings-search-indexes/${oldIndexName}`, {}));
  }
  
  async deleteItem(id: number): Promise<void> {
    
    return await firstValueFrom(this.http.delete<void>(`${this.apiContext}/listings-search-indexes/current/items/${id}`, {}));
  }
}

	